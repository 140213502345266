import React from 'react';
import {Helmet} from 'react-helmet';

class PrivacyPolicy extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Privacy Policy | Tintash</title>
        </Helmet>
        <section className="privacy__landing-section">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-12">
                <h1 className="privacy__landing-heading">Privacy Policy</h1>
                <p className="privacy__landing-subheading">Last Updated: 10th of May, 2022</p>
                <p className="privacy__landing-description">
                  At Tintash, your privacy is very important to us. Accordingly, we have prepared
                  this Policy in order for you to understand how we collect, communicate, disclose
                  and make use of personal information. The following outlines our privacy policy.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="privacy__content-section">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-10 mx-auto">
                <div className="text-left">
                  <p className="privacy__section-description">
                    The summary text, where it appears, provides a plain English version of the
                    topics covered in this policy. Please do read the entire text, as the summary
                    doesn’t capture all details.
                  </p>
                  <p className="privacy__section-description">
                    Thank you for visiting our website.
                  </p>
                  <p className="privacy__section-description">
                    Tintash is a mobile and web development company that provides managed remote
                    teams from around the globe. We work closely with you to design and build your
                    digital products. Through our website we aim to provide you with clear and well
                    organized information about our services. We want to make it easy for you to
                    understand what we do and how to reach us.
                  </p>
                  <p className="privacy__section-description">
                    This privacy policy explains what personal data is collected from you while you
                    use the Tintash website, how we use it, and how you can exercise meaningful
                    control over it. While you are here, we collect some personal information such
                    as the device that you are using and your IP address. In addition, you may
                    choose to provide information such as your name, email address, location and
                    phone number through our ‘contact us’ form if you want to get in touch with us
                    to begin a dialogue about working together.
                  </p>
                  <h5 className="privacy__section-greybox">
                    Summary: We collect limited personal information with the purpose of improving
                    your experience on our website. Most of this information is provided by you
                    directly through our contact form if you choose to do so.{' '}
                  </h5>
                  <p className="privacy__section-description">
                    Tintash is the controller for the processing of your personal data when you use
                    our services, in accordance with this privacy policy. As a data controller,
                    Tintash is responsible for determining why and how your personal data is
                    processed and is also responsible for complying with data protection
                    legislation.
                  </p>
                  <h5 className="privacy__section-greybox">
                    Summary: You can contact Tintash regarding the processing of your personal data
                    by reaching us at the contact information provided at the end of this policy.{' '}
                  </h5>
                  <h4 className="privacy__section-heading">Webserver Statistics</h4>
                  <p className="privacy__section-description">
                    Tintash website collects data for analytics and user journey improvements. This
                    is based on statistics regarding the visits to the website. This information
                    helps enhance the overall experience you have while using the website. All of
                    this statistical information is anonymous and we cannot identify a person based
                    on this unless a person signs up and provides their personal information on the
                    website.
                  </p>
                  <h4 className="privacy__section-heading">Telephone Inquiries</h4>
                  <p className="privacy__section-description">
                    When you opt to reach us via telephone, the number might be stored. This is used
                    by our client engagement team to follow up and communicate with you in order to
                    understand your requirements and needs better.
                  </p>
                  <h4 className="privacy__section-heading">Signup Form</h4>
                  <p className="privacy__section-description">
                    Sign up form is used to collect basic information that you enter directly, for
                    our sales team to be able to contact you and learn a little about the solution
                    and services that you are looking for. This form asks for your full name, email
                    address, phone number, country, and project description.
                  </p>
                  <p className="privacy__section-description">
                    Your personal information like name and phone number is used by our team to
                    reach out to you to facilitate getting a quote/obtaining our services. Some of
                    this information is also needed for signing legal contracts and NDAs. A
                    description of the project helps us understand the business problem that
                    requires our assistance, and how to align our resources in the most efficient
                    way possible to facilitate the clients’ journey with us. This information is
                    sent to our CRM to ensure a smooth client journey.
                  </p>
                  <h4 className="privacy__section-heading">Third Party Service Providers</h4>
                  <p className="privacy__section-description">
                    Tintash uses third parties and vendors (“Third Party Service Providers”) to
                    process data on our behalf under appropriate agreements.
                  </p>
                  <p className="privacy__section-description">
                    This includes third party services to maintain and improve the website’s
                    content. Through these service providers we process personal data to understand
                    user interaction with our website and to obtain contact details where users
                    provide it directly through our contact form. The list of these third party
                    service providers and the product or service being used is as follows:
                  </p>
                  <h4 className="privacy__section-heading">
                    {' '}
                    List of Third Party Service Providers
                  </h4>
                  <div className="table-section">
                    <table>
                      <tr>
                        <th className="privacy__section-heading">Third Party Service Provider</th>
                        <th className="privacy__section-heading">Product/Service</th>
                        <th className="privacy__section-heading">Link to Privacy Policy</th>
                      </tr>
                      <tr>
                        <td className="privacy__section-description">Google Ads</td>
                        <td className="privacy__section-description">Google Search Ads</td>
                        <td>
                          <a href="https://business.safety.google/adsprocessorterms/">
                            https://business.safety.google/adsprocessorterms/
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td className="privacy__section-description">Straight North </td>
                        <td className="privacy__section-description">
                          PPC Campaign Conversion Tracking
                        </td>
                        <td>
                          <a href="https://www.straightnorth.com/privacy-policy/ ">
                            https://www.straightnorth.com/privacy-policy/{' '}
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td className="privacy__section-description">Paperform</td>
                        <td className="privacy__section-description">Embedded Sign up form</td>
                        <td>
                          <a href=" https://paperform.co/privacy/">https://paperform.co/privacy/</a>
                        </td>
                      </tr>
                      <tr>
                        <td className="privacy__section-description">Google Workspace</td>
                        <td className="privacy__section-description">Email and Google sheets</td>
                        <td>
                          <a href=" https://policies.google.com/privacy?hl=en   ">
                            {' '}
                            https://policies.google.com/privacy?hl=en
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td className="privacy__section-description">Pipedrive</td>
                        <td className="privacy__section-description">CRM </td>
                        <td>
                          <a href="https://www.pipedrive.com/en/privacy  ">
                            {' '}
                            https://www.pipedrive.com/en/privacy
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td className="privacy__section-description">Integromat</td>
                        <td className="privacy__section-description">
                          Automation tool to link forms with CRM
                        </td>
                        <td>
                          <a href=" https://www.integromat.com/en/help/privacy-notice  ">
                            {' '}
                            https://www.integromat.com/en/help/privacy-notice
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td className="privacy__section-description">HelloSign </td>
                        <td className="privacy__section-description">Contract signing </td>
                        <td>
                          <a href="  https://www.hellosign.com/privacy ">
                            {' '}
                            https://www.hellosign.com/privacy
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td className="privacy__section-description">Google Analytics </td>
                        <td className="privacy__section-description">
                          Anonymous analytics and attribution information
                        </td>
                        <td>
                          <a
                            href=" https://policies.google.com/privacy?hl=en-US
 
 "
                          >
                            {' '}
                            https://policies.google.com/privacy?hl=en-US
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td className="privacy__section-description">Hotjar</td>
                        <td className="privacy__section-description">
                          Anonymous user interaction heatmaps
                        </td>
                        <td>
                          <a
                            href="https://www.hotjar.com/privacy/ 
 "
                          >
                            {' '}
                            https://www.hotjar.com/privacy/{' '}
                          </a>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <h4 className="privacy__section-heading">How Your Information May Be Used</h4>
                  <p className="privacy__section-description">
                    We use the information solely for fulfilling the purposes specified by us and
                    for other compatible purposes.
                  </p>
                  <p className="privacy__section-description">
                    Tintash may share personal data with Third Party Service Providers to perform
                    tasks on behalf of and under our instructions such as technical support, client
                    education, billing, analytics, and communication.
                  </p>
                  <p className="privacy__section-description">
                    We will not distribute or share your personal information with third parties
                    without your knowledge and consent, except under the following circumstances:
                    (a) If you use an account or profile associated with another party, we may share
                    your information with the owners of that profile (for example, if you are an
                    employee using your organization’s account or profile to communicate with us
                    regarding our site and/or services); (b) With our corporate family of companies
                    that are related by common ownership or control, to enable us to provide (or
                    support us in providing) our services; (c) If Tintash is acquired by or merged
                    with another company (in this event, Tintash will use reasonable efforts to
                    notify you before information about you is transferred and becomes subject to a
                    different privacy policy); (d) With Third Party Service Providers of Tintash to
                    perform tasks on behalf of and under our instructions; (e) In response to
                    subpoenas, court orders, or legal process, or to establish or exercise our
                    rights to defend against legal claims; (f) If we believe it is necessary to
                    investigate, prevent, or take action regarding illegal activities, suspected
                    fraud, safety of person or property, or as otherwise required by law; (g) For
                    any purpose disclosed by us when you provide the information; or (h) We may also
                    share aggregated information and other anonymized information for regulatory
                    compliance, industry and market analysis, demographic profiling, marketing and
                    advertising, and other business purposes.
                  </p>
                  <h4 className="privacy__section-heading">Data Subject Rights</h4>
                  <p className="privacy__section-description">
                    Tintash is committed to ensuring all of your data protection rights are
                    protected. Listed below are the data protection rights that you have, as listed
                    on the gdpr.eu website:
                  </p>
                  <p className="privacy__section-description">
                    The right to access – You have the right to request Tintash for copies of your
                    personal data.
                  </p>
                  <p className="privacy__section-description">
                    The right to rectification – You have the right to request that Tintash correct
                    any information you believe is inaccurate. You also have the right to request
                    Tintash to complete the information you believe is incomplete.
                  </p>
                  <p className="privacy__section-description">
                    The right to erasure – You have the right to request that Tintash erase your
                    personal data, under certain conditions, if the continued processing of that
                    personal information is not justified.
                  </p>
                  <p className="privacy__section-description">
                    The right to restrict processing – You have the right to request that Tintash
                    restrict the processing of your personal data, under certain conditions.
                  </p>
                  <p className="privacy__section-description">
                    The right to object to processing – You have the right to object to Tintash’s
                    processing of your personal data, under certain conditions.
                  </p>
                  <p className="privacy__section-description">
                    The right to data portability – You have the right to request that Tintash
                    transfer the data that we have collected to another organization, or directly to
                    you, under certain conditions.
                  </p>
                  <h4 className="privacy__section-heading">
                    Storage, Transfer and Security of Data
                  </h4>
                  <p className="privacy__section-description">
                    We will not retain your personal information longer than necessary for the
                    purposes for which it was collected.
                  </p>
                  <p className="privacy__section-description">
                    Tintash may share personal data with Third Party Service Providers under
                    appropriate agreements whereby they must agree to use such personal data only
                    for the purposes for which they have been engaged by Tintash.
                  </p>
                  <p className="privacy__section-description">
                    Your personal information may be transferred to, stored, and processed in the
                    United States or a country that is not regarded as providing the same level of
                    protection for personal information as the laws of your home country. We have
                    put in place appropriate safeguards (such as contractual commitments) in
                    accordance with applicable legal requirements to provide adequate protection for
                    your personal information.
                  </p>
                  <p className="privacy__section-description">
                    The security of your data is important to us, but please do remember that no
                    method of transmission over the Internet or method of electronic storage is 100%
                    secure. While we strive to use commercially acceptable means to protect your
                    personal data, we cannot guarantee its absolute security.
                  </p>
                  <h4 className="privacy__section-heading">Use of Cookies</h4>
                  <p className="privacy__section-description">
                    Tintash website uses cookies to collect standard Internet log information and
                    visitor behaviour information. Cookies are files placed on your browser that
                    record and store this information to recognise your browser every time, and to
                    enhance your user experience. We use the Osano platform for our cookie
                    management.
                  </p>
                  <p className="privacy__section-description">
                    We use these cookies to keep you signed in and to understand the user journey.
                    This information helps us make the experience more customized for you, and to
                    make improvements in the process.
                  </p>
                  <h4 className="privacy__section-heading">Types of Cookies Used</h4>
                  <p className="privacy__section-description">
                    Functional - Tintash website uses these cookies to identify you on the website
                    and store your preferences.
                  </p>
                  <p className="privacy__section-description">
                    Analytics - Tintash website uses these cookies to collect anonymous information
                    regarding how you use the website, as described under the use of Third Party
                    Providers.
                  </p>
                  <p className="privacy__section-description">
                    Marketing - Marketing cookies are used on the Tintash website to store
                    information of the pages you visit in order to show relevant pages to you. You
                    can choose to enable or disable these cookies by accessing the cookie setting on
                    your browser.
                  </p>
                  <p className="privacy__section-description">
                    If you want to change your cookie preferences at any time, you can click the
                    cookie icon on the bottom right corner and change the settings you've previously
                    selected. If you would like to delete cookies or instruct your web browser to
                    delete or refuse cookies, you can do so by choosing appropriate browser
                    settings.
                  </p>
                  <p className="privacy__section-description">
                    For more information on how to manage cookies on some of the major browsers,
                    please see below:
                  </p>
                  <h4 className="privacy-browsers"> Google Chrome</h4>
                  <p className="privacy__section-description">
                    <a href="https://support.google.com/chrome/answer/95647?hl=en">
                      https://support.google.com/chrome/answer/95647?hl=en
                    </a>
                  </p>
                  <h4 className="privacy-browsers">Internet Explorer</h4>
                  <p className="privacy__section-description">
                    <a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookie">
                      https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies
                    </a>
                  </p>
                  <h4 className="privacy-browsers">Firefox</h4>
                  <p className="privacy__section-description">
                    <a href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?">
                      https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?
                    </a>
                  </p>
                  <h4 className="privacy-browsers"> Safari</h4>
                  <p className="privacy__section-description">
                    <a href="https://support.apple.com/en-au/guide/safari/sfri11471/">
                      https://support.apple.com/en-au/guide/safari/sfri11471/
                    </a>
                  </p>
                  <p className="privacy__section-description">
                    This Privacy Policy does not cover the use of cookies by any third-party links.
                    Tintash will not be liable for the personal privacy policies on these sites.
                    Please be aware that the privacy policies of these websites could vary from our
                    own.
                  </p>
                  <h4 className="privacy__section-heading"> Contact Information</h4>
                  <p className="privacy__section-description">
                    If you have any questions regarding this privacy policy, or how Tintash
                    processes personal data, please do not hesitate to contact us using the
                    following contact information.
                  </p>

                  <p className="privacy__section-description">
                    Email address:
                    <a href=" bd@tintash.com">bd@tintash.com </a>
                  </p>
                  <h4 className="privacy__section-heading"> GDPR Representative:</h4>
                  <p className="privacy__section-description">
                    Osano International Compliance Services Limited<br></br>
                    ATTN: AE4E<br></br>
                    25/28 North Wall Quay<br></br>
                    Dublin 1, D01 H104<br></br>
                    IRELAND
                  </p>
                  <h4 className="privacy__section-heading"> A Note about Children</h4>
                  <p className="privacy__section-description">
                    We do not intentionally gather personal information from visitors who are under
                    the age of 16. If a child under the age of 16 submits personal information to
                    Tintash and we learn that the personal information is the information of a child
                    under 16, we will attempt to delete the information as soon as possible. If you
                    believe that we might have any personal information from a child under 16,
                    please contact us at: <a href=" bd@tintash.com">bd@tintash.com. </a>
                  </p>
                  <p className="privacy__section-description">
                    This privacy policy was last updated in May 2022. We may update our privacy
                    policy from time to time. You are advised to review this Privacy Policy
                    periodically for any changes. Changes to this Privacy Policy are effective when
                    they are posted on this page.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default PrivacyPolicy;
